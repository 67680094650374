const defaultRows = () => {
  const arr = [];

  for (let i = 1; i <= 29; i++) {
    arr.push({
      value: i,
      label: `Row ${i}`,
    });
  }

  return arr;
};

const lowerZoneRows = () => {
  let arr = defaultRows();
  arr.push({
    value: 'WC',
    label: 'Row WC'
  })

  return arr;
}

export default {
  // NON_LOGE_ROWS: nonLogeRows(),
  // LOGE_ROWS: logeRows,
  LOWER_ZONE_ROWS: lowerZoneRows(),
  DEFAULT_ROWS: defaultRows(),
  REST_ROWS: [
    {
      value: 1,
      label: `Row 1`,
    },
    {
      value: 2,
      label: `Row 2`,
    },
    {
      value: 3,
      label: `Row STL`,
    },
  ],
  PLATINUM_CLUB_ROWS: [
    {
      value: 1,
      label: `Row 1`,
    },
    {
      value: 2,
      label: `Row 2`,
    },
    {
      value: 3,
      label: `Row 3`,
    },
    {
      value: 4,
      label: `Row 4`,
    },
  ],
};
