import React, { Component } from "react";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import _, { isNull } from "lodash";
import { CSVLink } from "react-csv";

import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Select,
  MenuItem,
  Tooltip,
} from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { red, green } from '@material-ui/core/colors';
import CDialog from "../components/CDialog";
import { isAllowedAction } from "../libs/rbac";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit * 3,
  },
  paper: {
    width: "100%",
    height: "calc(75vh)",
    marginTop: theme.spacing.unit * 3,
    overflow: "scroll",
  },
  tr: {
    '&:hover': {
       backgroundColor: "#fef8fa",
    },
  },
  trEven: {
    backgroundColor: '#f6f6f6',
    '&:hover': {
       backgroundColor: "#fef8fa",
    },
  },
  table: {
    minWidth: 700,
  },
  loadMoreBtns: {
    display: "flex",
    justifyContent: "center",
    position: "sticky",
    bottom: 0,
  },
  loadMoreBtn: {
    margin: "1rem",
    marginLeft: 0,
  },
  headerBtn: {
    marginRight: "1rem",
    marginBottom: ".5rem",
  },
});

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: null,
      editingOrderId: null,
      receiptNo: "",
      isReceiptFormOpen: false,
      isSubmitting: false,
      csvReport: null,
      isGeneratingReport: false,
      isSendingNotif: false,
      isResendingNotif: false,
      isNoteFormOpen: false,
      isResendFormOpen: false,
      orderNotes: "",
      isLoading: true,
      lastEvaluatedKey: null,
      isOrderDetailsOpen: false,
      orderStatus: 0,
      isResendingReceipt: false,
      emailToResendTo: "",
      limit: 25,
      copyText: "Copy email to clipboard?",
    };

    this.load50More = this.load50More.bind(this);
    this.load500More = this.load500More.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getOrders = this.getOrders.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.setOrderPrevTxns = this.setOrderPrevTxns.bind(this);
    this.resendReceipt = this.resendReceipt.bind(this);
    this.renderCarrierDetails = this.renderCarrierDetails.bind(this);
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    const startDate = "2018-01-01";

    const endDate = moment().add(1, "day").endOf("day").format("YYYY-MM-DD");

    const { orders, lastEvaluatedKey } = await API.get(
      "v2",
      `marketplace/orders/by/recent?startDate=${startDate}&endDate=${endDate}&orderStatus=0&limit=50`
    );

    const sorted = _.orderBy(orders, ["createdAt"], ["desc"]);
    this.setState({
      startDate,
      endDate,
      orders: sorted,
      isLoading: false,
      lastEvaluatedKey,
    });
    console.log("component did mount", this.state.lastEvaluatedKey);
  }

  async load50More() {
    try {
      this.setState({ isLoading: true, csvReport: null });
      const { startDate, endDate, orderStatus, lastEvaluatedKey } = this.state;
      if (lastEvaluatedKey === null) {
        alert(
          "No more orders to load. To see older orders, please expand the date range."
        );
        this.setState({ isLoading: false });
        return;
      }
      const response = await API.get(
        "v2",
        `marketplace/orders/by/recent?${
          lastEvaluatedKey
            ? `lastEvaluatedKey=${JSON.stringify(lastEvaluatedKey)}&`
            : ""
        }startDate=${startDate}&endDate=${endDate}&orderStatus=${orderStatus}&limit=50`
      );

      const newOrders = [...this.state.orders, ...response.orders];

      this.setState({
        orders: newOrders,
        lastEvaluatedKey: response.lastEvaluatedKey,
      });
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  async load500More() {
    try {
      this.setState({ isLoading: true, csvReport: null });
      const { startDate, endDate, orderStatus, lastEvaluatedKey } = this.state;
      if (lastEvaluatedKey === null) {
        alert(
          "No more orders to load. To see older orders, please expand the date range."
        );
        this.setState({ isLoading: false });
        return;
      }
      const response = await API.get(
        "v2",
        `marketplace/orders/by/recent?${
          lastEvaluatedKey
            ? `lastEvaluatedKey=${JSON.stringify(lastEvaluatedKey)}&`
            : ""
        }startDate=${startDate}&endDate=${endDate}&orderStatus=${orderStatus}&limit=500`
      );

      const newOrders = [...this.state.orders, ...response.orders];

      this.setState({
        orders: newOrders,
        lastEvaluatedKey: response.lastEvaluatedKey,
      });
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  async getOrders(e) {
    e.preventDefault();
    const { startDate, endDate, orderStatus } = this.state;
    try {
      this.setState({ isLoading: true });
      const { orders, lastEvaluatedKey } = await API.get(
        "v2",
        `marketplace/orders/by/recent?startDate=${startDate}&endDate=${endDate}&orderStatus=${orderStatus}&limit=50`
      );

      this.setState({
        orders: _.orderBy(orders, ["createdAt"], ["desc"]),
        lastEvaluatedKey,
        startDate,
        endDate,
        orderStatus,
      });
      console.log(this.state.lastEvaluatedKey);
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  orders(lastEvaluatedKey, limit = 50) {
    return API.get(
      "v2",
      `marketplace/orders/by/recent?limit=${limit}${
        lastEvaluatedKey
          ? `&lastEvaluatedKey=${JSON.stringify(lastEvaluatedKey)}`
          : ""
      }`
    );
  }

  refundOrder(id) {
    const { orders } = this.state;

    const newOrders = orders.map((order) => {
      if (order.id === id) {
        order.isRefunded = true;
        order.refundedDate = Date.now();
      }

      return order;
    });

    this.setState({ orders: newOrders });

    API.post("v2", `marketplace/orders/refund?orderId=${id}`);
  }

  async resendNotif(id) {
    try {
      this.setState({
        isResendingNotif: id,
      });

      await API.post(
        "v2",
        `marketplace/orders/resend-seller-notification?orderId=${id}`
      );

      this.setState({
        isResendingNotif: null,
      });
    } catch (e) {
      this.setState({
        isResendingNotif: null,
      });
    }
  }

  async sendNotif(id) {
    try {
      this.setState({
        isSendingNotif: id,
      });

      await API.post(
        "v2",
        `marketplace/orders/resend-seller-notification?orderId=${id}`
      );

      const newOrders = this.state.orders.map((order) => {
        if (order.id === id) {
          return {
            ...order,
            ...{
              isSellerNotificationSent: true,
            },
          };
        }

        return order;
      });

      this.setState({
        isSendingNotif: null,
        orders: newOrders,
      });
    } catch (e) {
      this.setState({
        isSendingNotif: null,
      });
    }
  }

  async resendReceipt({ id, email }) {
    this.setState({ isSubmitting: true });

    try {
      await API.post("v2", `marketplace/orders/${id}/resend-receipt`, {
        body: {
          email,
        },
      });

      this.setState({
        orderToResend: null,
        isResendFormOpen: false,
        emailToResendTo: "",
        isSubmitting: false,
      });
      CDialog.success("Success!", "Email resent.");
    } catch (e) {
      this.setState({
        orderToResend: null,
        isResendFormOpen: false,
        emailToResendTo: "",
        isSubmitting: false,
      });
      CDialog.error("Error!", "Failed to resent the email");
    }
  }

  markAsSent(id) {
    const { orders } = this.state;

    const newOrders = orders.map((order) => {
      if (order.id === id) {
        order.isSent = true;
      }

      return order;
    });

    this.setState({ orders: newOrders });

    API.post("v2", `marketplace/orders/${id}/mark-as-sent`);
  }

  async markAsPaid(id) {
    const { orders } = this.state;

    this.setState({
      isSubmitting: true,
    });

    const receiptNo = moment().format("MM/DD/YY");

    try {
      await API.post("v2", `marketplace/orders/mark-paid?orderId=${id}`);

      const newOrders = orders.map((order) => {
        if (order.id === id) {
          order.isPaid = true;
          order.receiptNo = receiptNo;
        }

        return order;
      });

      this.setState({
        orders: newOrders,
        editingOrder: null,
        isReceiptFormOpen: false,
        receiptNo: "",
        isSubmitting: false,
      });
    } catch (e) {
      this.setState({
        editingOrder: null,
        isReceiptFormOpen: false,
        receiptNo: "",
        isSubmitting: false,
      });
    }
  }

  async verifyBuyerEmail(order) {
    this.setState({ isLoading: true });
    const { orders } = this.state;

    try {
      await API.post("v2", `marketplace/accounts/verify-buyer-email`, {
        body: {
          emailAddress: order.email,
        },
      });
  
      const newOrders = orders.map((item) => {
        if (item.email === order.email) {
          item.emailVerified = true;
        }
        return item;
      });
  
      this.setState({
        orders: newOrders,
        isLoading: false
      });
    } catch (error) {
      this.setState({ isLoading: false });
      CDialog.error("Error", "Failed to verify the email");
    }
  }
  
  async unverifyBuyerEmail(order) {
    this.setState({ isLoading: true });
    const { orders } = this.state;

    try {
      await API.post("v2", `marketplace/accounts/unverify-buyer-email`, {
        body: {
          emailAddress: order.email,
        },
      });
  
      const newOrders = orders.map((item) => {
        if (item.email === order.email) {
          item.emailVerified = false;
        }
        return item;
      });
  
      this.setState({
        orders: newOrders,
        isLoading: false
      });
    } catch (error) {
      this.setState({ isLoading: false });
      CDialog.error("Error", "Failed to unverify the email");
    }
  }

  handleReceiptNoChange = (e) => {
    this.setState({
      receiptNo: e.target.value,
    });
  };

  handleEmailToResendChange = (e) => {
    this.setState({
      emailToResendTo: e.target.value,
    });
  };

  handleOrderNotesChange = (e) => {
    this.setState({
      orderNotes: e.target.value,
    });
  };

  openReceiptForm = (order) => {
    this.setState({
      editingOrder: order,
      isReceiptFormOpen: true,
      receiptNo: "",
    });
  };

  openNotesForm = (order) => {
    this.setState({
      orderToAddNote: order,
      isNoteFormOpen: true,
      orderNotes: "",
    });
  };

  handleFormClose = () => {
    this.setState({
      editingOrder: null,
      isReceiptFormOpen: false,
      isResendFormOpen: false,
      receiptNo: "",
    });
  };

  renderReceiptForm() {
    const { isReceiptFormOpen, editingOrder, isSubmitting } = this.state;

    return (
      <Dialog
        open={isReceiptFormOpen}
        onClose={this.handleFormClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Mark Order as Paid</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: "2rem" }}>
            Enter receipt no. for {editingOrder && editingOrder.description} by{" "}
            {editingOrder && editingOrder.name}
          </DialogContentText>
          <TextField
            autoFocus
            id="name"
            label="Receipt No."
            type="receiptNo"
            onChange={this.handleReceiptNoChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={isSubmitting} onClick={this.handleFormClose}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            variant="contained"
            onClick={() => this.markAsPaid(editingOrder.id)}
            color="primary"
          >
            Mark as Paid
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderResendReceipt() {
    const { isResendFormOpen, orderToResend, isSubmitting } = this.state;

    if (!isResendFormOpen && !orderToResend) {
      return null;
    }

    return (
      <Dialog
        open={isResendFormOpen}
        onClose={this.handleFormClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Resend Email Receipt</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: "1rem" }}>
            <Typography variant="body1">
              {orderToResend.game.shortName}{" "}
              {moment
                .tz(orderToResend.game.date, orderToResend.game.timezone)
                .format("MMM DD YYYY hh:mm A")}
            </Typography>
            <Typography variant="body1">
              {orderToResend.name} - {orderToResend.email}
            </Typography>
          </DialogContentText>
          <TextField
            autoFocus
            id="name"
            label="Email Address to send to"
            type="receiptNo"
            defaultValue={orderToResend.email}
            onChange={this.handleEmailToResendChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={isSubmitting} onClick={this.handleFormClose}>
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            variant="contained"
            onClick={() =>
              this.resendReceipt({
                id: orderToResend.id,
                email: this.state.emailToResendTo
                  ? this.state.emailToResendTo
                  : orderToResend.email,
              })
            }
            color="primary"
          >
            Resend Email Receipt
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  handleNotesFormClose() {
    this.setState({
      orderToAddNote: null,
      isNoteFormOpen: false,
      orderNotes: "",
    });
  }

  async saveOrderNotes(id) {
    try {
      const { orders, orderNotes } = this.state;
      this.setState({ isSubmitting: true });

      await API.post("v2", `marketplace/orders/notes/add`, {
        body: { orderId: id, note: orderNotes },
      });

      const newOrders = orders.map((order) => {
        if (order.id === id) {
          order.notes = order.notes
            ? order.notes.concat(orderNotes)
            : [orderNotes];
        }

        return order;
      });

      this.setState({ orders: newOrders });

      this.setState({ isSubmitting: false });

      this.handleNotesFormClose();
    } catch (e) {
      console.log(e);
      this.setState({ isSubmitting: false });

      this.handleNotesFormClose();
    }
  }

  renderNoteForm() {
    const { isNoteFormOpen, orderToAddNote, isSubmitting } = this.state;

    return (
      <Dialog
        open={isNoteFormOpen}
        onClose={() => this.handleNotesFormClose()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Notes</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: "2rem" }}>
            Add notes for {orderToAddNote && orderToAddNote.description} by{" "}
            {orderToAddNote && orderToAddNote.name}
          </DialogContentText>
          <TextField
            autoFocus
            id="name"
            label="Note"
            type="text"
            name="orderNotes"
            onChange={this.handleOrderNotesChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            onClick={() => this.handleNotesFormClose()}
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            variant="contained"
            onClick={() => this.saveOrderNotes(orderToAddNote.id)}
            color="primary"
          >
            Add Note
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  emailIsDerivationOfName(email, fullName){
    const first = fullName.split(" ")[0]
    const last = fullName.split(" ")[1]

    const includesName = (email, name) => {
        email = email.split("@")[0].toLowerCase()
        name = name.toLowerCase()
    
        if (name.length <= 2) {
            return email.includes(name)
        }
    
        for (let i = 0; i < name.length - 2; i++) {
            if (email.includes(name.substring(i, i+3))){
                return true
            }
        }
        return false
    }

    if (!includesName(email, first) && !includesName(email, last)){
        return false
    } else {
        return true
    }
  }

  isEmailRedFlag(email, name) {
    if (
      email.includes("hotmail") ||
      email.includes("outlook") ||
      email.includes("protonmail") ||
      email.includes("yopmail") ||
      email.includes("yahoo") ||
      email.includes("fastmail") ||
      email.includes("googlemail")
    ) {
      return true;
    }

    if (!this.emailIsDerivationOfName(email, name)) {
      return true;
    }
    return false;
  }

  isAvsMatch(code) {
    switch (code) {
      case "D":
      case "M":
      case "P":
      case "W":
      case "X":
      case "Y":
      case "Z": {
        return true;
      }

      default:
        return false;
    }
  }

  setOrderPrevTxns(id, transactions) {
    const newOrders = this.state.orders.map((order) => {
      if (order.id === id) {
        return {
          ...order,
          ...{
            previousTransactions: transactions,
          },
        };
      }

      return order;
    });

    this.setState({
      orders: newOrders,
    });
  }
  renderCarrierDetails(mobileNumberDetails) {
    if (!mobileNumberDetails) {
      return null;
    } else if (!mobileNumberDetails.constructor === Object) {
      return null;
    } else if (!mobileNumberDetails.current_carrier) {
      return null;
    } else if (!mobileNumberDetails.current_carrier.constructor === Object) {
      return null;
    } else {
      return (
        <div>
          <Typography variant="body2">Carrier Details</Typography>
          <Typography variant="body1">
            {mobileNumberDetails.current_carrier.name}
          </Typography>
        </div>
      );
    }
  }
  renderDetails(order) {
    const { currUser } = this.props;
    const { userGroup } = currUser;

    if (order.source) {
      const { source, card, mobileNumberDetails } = order;

      return (
        <React.Fragment>
          {order.isNewUser ? (
            <Typography
              variant="body2"
              color="secondary"
              style={{ marginBottom: ".5rem" }}
            >
              New User
            </Typography>
          ) : (
            ""
          )}
          {order.ipFlag ? (
            <Typography
              variant="body2"
              color="secondary"
              style={{ marginBottom: ".5rem" }}
            >
              IP address has had a failed transaction before
            </Typography>
          ) : (
            ""
          )}
          {order.failedTrx ? (
            <Typography
              variant="body2"
              color="secondary"
              style={{ marginBottom: ".5rem" }}
            >
              Has {order.failedTrx} failed txns
            </Typography>
          ) : (
            ""
          )}
          <Typography variant="body1">{order.name}</Typography>
          <Typography variant="body1">{order.mobileNo}</Typography>
          <Typography
            variant="body1"
            style={{
              fontWeight: this.isEmailRedFlag(order.email, order.name) ? "bold" : "normal",
              color: this.isEmailRedFlag(order.email, order.name)
                ? "rgb(255, 23, 68)"
                : "rgba(0, 0, 0, 0.87)",
            }}
          >
            {order.email}
          </Typography>

          {order.emailVerified ? (
            <>
              <Typography style={{color: green[800]}}>Verified &#10003;</Typography>
              {isAllowedAction(userGroup) && (
                <Button 
                  variant="contained" 
                  color="secondary" 
                  size="small" 
                  onClick={() => this.unverifyBuyerEmail(order)}
                  disabled={this.state.isLoading}
                >
                  {this.state.isLoading ? "Loading..." : "Unverify" }
                </Button>
              )}              
            </>            
          ): (
           <>
            <Typography color="secondary">Not Verified &#10005;</Typography>
            {isAllowedAction(userGroup) && (
              <Button 
                variant="contained"
                color="primary"
                size="small"
                onClick={() => this.verifyBuyerEmail(order)}
                disabled={this.state.isLoading}
              >
                {this.state.isLoading ? "Loading..." : "Verify" }
              </Button>    
            )}            
           </>
          )}
                   
          <Typography variant="body2" style={{ marginTop: "1rem" }}>Source</Typography>
          <Typography variant="body1" style={{ marginBottom: "0rem" }}>
            {source.ip} { (source.city ? source.city.replace("-", "") : null)} {" "}
            {(source.region ? source.region.replace("-", "") : null)} {(source.country ? source.country.replace("-", "") : null)}
          </Typography>
          <Typography
            variant="body1"
            style={{
              marginBottom: "0rem",
            }}
          >
            ISP: {source.ISP} {source.host}
          </Typography>
          {source.vpn ? (
            <Typography
              variant="body1"
              style={{
                marginBottom: "0rem",
                fontWeight: "bold",
                color: "rgb(255, 23, 68)",
              }}
            >
              VPN
            </Typography>
          ) : null}
          {source.proxy ? (
            <Typography
              variant="body1"
              style={{
                marginBottom: "0rem",
                fontWeight: "bold",
                color: "rgb(255, 23, 68)",
              }}
            >
              Proxy{" "}
            </Typography>
          ) : null}
          {source.bot_status || source.is_crawler ? (
            <Typography
              variant="body1"
              style={{
                marginBottom: "0rem",
                fontWeight: "bold",
                color: "rgb(255, 23, 68)",
              }}
            >
              Potential Bot{" "}
            </Typography>
          ) : null}
          {source.tor ? (
            <Typography
              variant="body1"
              style={{
                marginBottom: "0rem",
                fontWeight: "bold",
                color: "rgb(255, 23, 68)",
              }}
            >
              Tor Browser{" "}
            </Typography>
          ) : null}
          {source.recent_abuse ? (
            <Typography
              variant="body1"
              style={{
                marginBottom: "0rem",
                fontWeight: "bold",
                color: "rgb(255, 23, 68)",
              }}
            >
              Recent IP Abuse{" "}
            </Typography>
          ) : null}
          {source.fraud_score > 0 ? (
            <Typography
              variant="body1"
              style={{
                marginBottom: "0rem",
                fontWeight: "bold",
                color: "rgb(255, 23, 68)",
              }}
            >
              IP Fraud Score: {source.fraud_score}/100
            </Typography>
          ) : null}

          <Typography variant="body2" style={{ marginTop: "1rem" }}>
            Card Details
          </Typography>
          <Typography variant="body1">{card.number}</Typography>
          <Typography variant="body1">
            {card.brand ? card.brand.replace("-", "") : ""}{" "}
            {card.bank ? card.bank.replace("-", "") : ""}{" "}
            {card.city ? card.city.replace("-", "") : ""}{" "}
            {card.country ? card.country.replace("-", "") : ""}
          </Typography>
          <Typography>
            {card.url.replace("-", "")} {card.phone.replace("-", "")}
          </Typography>
          <Typography style={{ marginTop: ".5rem", marginBottom: "1rem" }}>
            Address Verification:{" "}
            <strong
              style={{
                color: this.isAvsMatch(card.verificationResponse)
                  ? "#4caf50"
                  : "rgb(255, 23, 68)",
              }}
            >
              {this.isAvsMatch(card.verificationResponse)
                ? "Postal code matches"
                : "Postal code doesn't match"}
            </strong>
          </Typography>
          <Typography variant="body2">
            3DS
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "1rem" }}>
            {order.isThreeDS ? "Yes" : "No"}
          </Typography>
          <br />
          {this.renderCarrierDetails(mobileNumberDetails)}
        </React.Fragment>
      );
    } else {
      if (order.notes) {
        return (
          <React.Fragment>
            <dl>
              <dd>{order.name}</dd>
              <dd>{order.email}</dd>
              <dd>{order.mobileNo}</dd>
              <dd style={{ marginBottom: "1rem" }}></dd>
              {order.notes.map((note) => {
                return <dd>{note}</dd>;
              })}
            </dl>
          </React.Fragment>
        );
      } else {
        return <Typography variant="body1">-</Typography>;
      }
    }
  }

  openOrderDetails(order) {
    this.setState({
      isOrderDetailsOpen: true,
      orderToView: order,
    });
  }

  renderOrderDetails() {
    const { isOrderDetailsOpen, orderToView } = this.state;
    if (!isOrderDetailsOpen) {
      return;
    }

    return (
      <Dialog
        open={isOrderDetailsOpen}
        onClose={() => this.setState({ isOrderDetailsOpen: false })}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Order Details</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marginBottom: "2rem" }}>
            {this.renderDetails(orderToView)}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  team(slug) {
    switch (slug) {
      case "calgary-flames": {
        return "CGY";
      }

      case "edmonton-oilers": {
        return "EDM";
      }

      case "winnipeg-jets": {
        return "WPG";
      }

      case "vancouver-canucks": {
        return "VAN";
      }

      case "toronto-maple-leafs": {
        return "TOR";
      }
      
      case "toronto-raptors": {
        return "RAPS";
      }

      case "ottawa-senators": {
        return "OTT";
      }

      case "toronto-blue-jays": {
        return "TBJ";
      }

      case "edmonton-elks": {
        return "ELK";
      }

      case "winnipeg-blue-bombers": {
        return "WBB";
      }

      case "toronto-argonauts": {
        return "ARG";
      }

      case "calgary-stampeders": {
        return "STA";
      }

      case "ottawa-redblacks": {
        return "ORB";
      }

      case "bc-lions": {
        return "BCL";
      }

      case "saskatchewan-roughriders": {
        return "SSK";
      }

      case "hamilton-tigercats": {
        return "HAM";
      }

      case "grey-cup": {
        return "GCUP";
      }

      default:
        return "";
    }
  }

  teamLongName(slug) {
    switch (slug) {
      case "calgary-flames": {
        return "Flames";
      }

      case "edmonton-oilers": {
        return "Oilers";
      }

      case "winnipeg-jets": {
        return "Jets";
      }

      case "vancouver-canucks": {
        return "Canucks";
      }

      case "toronto-maple-leafs": {
        return "Leafs";
      }
      
      case "toronto-raptors": {
        return "Raptors";
      }

      case "ottawa-senators": {
        return "Senators";
      }

      case "toronto-blue-jays": {
        return "Blue Jays";
      }
      
      case "edmonton-elks": {
        return "Elks";
      }
      
      case "winnipeg-blue-bombers": {
        return "Blue Bombers";
      }
      
      case "toronto-argonauts": {
        return "Argonauts";
      }
      
      case "calgary-stampeders": {
        return "Stampeders";
      }
      
      case "ottawa-redblacks": {
        return "Redblacks";
      }
      
      case "bc-lions": {
        return "Lions";
      }

      case "saskatchewan-roughriders": {
        return "Roughriders";
      }

      case "hamilton-tigercats": {
        return "Tiger-Cats";
      }

      case "grey-cup": {
        return "Grey Cup";
      }

      default:
        return "";
    }
  }

  renderNotes(notes) {
    // Hotfix for red flag notes
    const indexOfCreditCard = _.findIndex(notes, (note) => {
      return new RegExp("####").test(note);
    });

    if (indexOfCreditCard !== -1) {
      return notes.map((note, index) => {
        if (index > indexOfCreditCard + 1) {
          return (
            <React.Fragment>
              <span key={note}>- {note.replace("-", "")}</span>
              <br />
            </React.Fragment>
          );
        }

        return null;
      });
    } else {
      return notes.map((note) => (
        <React.Fragment>
          <span>- {note}</span>
          <br />
        </React.Fragment>
      ));
    }
  }

  copyEmail = (event) => {
    const copyText = event.target.innerHTML;
    navigator.clipboard.writeText(copyText);
    this.setState({...this.state, copyText: `Copied ${copyText} to clipboard!`});
  }

  handleTooltipClose = () => {
    this.setState({...this.state, copyText: "Copy email to clipboard?"});
  }
  handleTooltipOrder = () => {
    this.setState({...this.state, copyText: "Copy order number to clipboard?"});
  }

  renderOrders() {
    const { classes } = this.props;

    if (this.state.orders && this.state.orders.length === 0) {
      return (
        <Typography
          align="center"
          color="textSecondary"
          variant="headline"
          style={{ marginTop: "5rem" }}
        >
          No orders for this date.
        </Typography>
      );
    }

    return (
      this.state.orders &&
      this.state.orders.length > 0 && (
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ fontFamily: "Roboto Mono", whiteSpace: "nowrap" }}
                padding="checkbox"
              >
                Order Number
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Add Note
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Notes
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Team
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Date
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Name
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Email
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Verified
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Game
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Game Date
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Seat
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                No of Seats
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Total
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Seller Email
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
              {
                // Need this hack to fix extra space on text selection
              }
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Ticket Sent
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Paid
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Send Notif
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Resend Confirmation
              </TableCell>
              <TableCell
                style={{ fontFamily: "Roboto Mono" }}
                padding="checkbox"
              >
                Refunded
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.orders.map((order, key) => {
              if (!order.seat) {
                return null;
              }

              const { game } = order;
              let gameName = "-";
              let gameDate = "-";

              if (game) {
                gameName = `${game.opponent} at ${this.teamLongName(
                  game.homeTeamSlug
                )}`;
                gameDate =
                (game.isTbd)
                  ? "TBD"
                  : moment
                      .tz(order.game.date, order.game.timezone)
                      .format("MM/DD/YYYY");
              }
              const sellerEmail = order.seat.sellerEmail && order.seat.sellerEmail !== "-"
              ? String(order.seat.sellerEmail).trim()
              : "-";

              return (
                <TableRow key={order.id} className={key % 2 === 0 ? classes.trEven : classes.tr}>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                  <Tooltip disableFocusListener title={this.state.copyText} onOpen={this.handleTooltipOrder} onClose={this.handleTooltipOrder}>
                    <span id={`${order.id}-${order.trackingNumber}`} style={{cursor: "pointer"}} onClick={(e) => this.copyEmail(e)}>
                      {order.trackingNumber ? order.trackingNumber : "-"}
                    </span>
                  </Tooltip>
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      size="small"
                      onClick={() => this.openNotesForm(order)}
                    >
                      Add Note
                    </Button>
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {order.notes ? this.renderNotes(order.notes) : "-"}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {game ? this.team(game.homeTeamSlug) : '-'}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {moment(order.createdAt).format("MMM DD,YYYY")}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono", cursor: "pointer" }}
                    component="th"
                    scope="row"
                    onClick={() => this.openOrderDetails(order)}
                  >
                    {order.name}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                  <Tooltip disableFocusListener title={this.state.copyText} onClose={this.handleTooltipClose}>
                    <span id={order.id} style={{cursor: "pointer"}} onClick={(e) => this.copyEmail(e)}>{String(order.email).trim()}</span>
                  </Tooltip>
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {order.emailVerified ? (
                      <CheckIcon style={{ color: green[800] }} />
                    ): (
                      <CloseIcon style={{ color: red[800] }} />
                    )}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {gameName}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {gameDate}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {[
                      "Sportsnet",
                      "Sky Lounge",
                      "Press Level",
                      "Loge",
                      "REST",
                      "TM Lounge",
                      "WestJet Flight Deck",
                      "TM LOUNGE",
                      "WESTJET FLIGHT DECK",
                      "PREMIUM SUITE LOUNGE",
                    ].some((x) => order.seat.zone.includes(x))
                      ? `${order.seat.zone} ${
                          order.seat.zoneNo ? order.seat.zoneNo : ""
                        }`
                      : order.seat.zoneNo}{" "}
                    Row {order.seat.row} {order.isAisleSeat ? "Aisle" : ""}{" "}
                    {order.isInstantDelivery ? (
                      <strong style={{ color: '#ff1744' }}>Insta</strong>
                    ) : ""}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {order.noOfSeats}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    CAD$ {(order.ticketPrice * order.noOfSeats).toFixed(2)}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                  <Tooltip disableFocusListener title={this.state.copyText} onClose={this.handleTooltipClose}>
                    <span id={order.id} style={{cursor: "pointer"}} onClick={(e) => this.copyEmail(e)}>{sellerEmail}</span>
                  </Tooltip>
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {
                      // Need this hack to fix extra space on text selection
                    }
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {order.isSent ? (
                      <Button disabled={true} variant="contained" size="small">
                        Sent
                      </Button>
                    ) : (
                      <Button
                        color={order.isRefunded ? "":"secondary"}
                        variant="contained"
                        size="small"
                        onClick={() => this.markAsSent(order.id)}
                        disabled={order.isRefunded}
                      >
                        Send
                      </Button>
                    )}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {order.isPaid ? (
                      <Button disabled={true} variant="contained" size="small">
                        Paid
                      </Button>
                    ) : (
                      <Button
                        color={order.isRefunded ? "":"secondary"}
                        variant="contained"
                        size="small"
                        onClick={() => this.markAsPaid(order.id)}
                        disabled={order.isRefunded}
                      >
                        Pay
                      </Button>
                    )}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {!order.isSellerNotificationSent ? (
                      <Button
                        color={order.isRefunded ? "":"secondary"}
                        variant="contained"
                        size="small"
                        onClick={() => this.sendNotif(order.id)}
                        disabled={this.state.isSendingNotif === order.id}
                      >
                        Send Notif
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => this.resendNotif(order.id)}
                        disabled={this.state.isResendingNotif === order.id}
                        style={{fontWeight:"700"}}
                      >
                        Resend Notif
                      </Button>
                    )}
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    <Button
                      color={order.isRefunded ? "":"secondary"}
                      variant="contained"
                      size="small"
                      onClick={() =>
                        this.setState({
                          isResendFormOpen: true,
                          orderToResend: order,
                        })
                      }
                      disabled={this.state.isResendingReceipt === order.id}
                    >
                      Resend Confirmation
                    </Button>
                  </TableCell>
                  <TableCell
                    style={{ fontFamily: "Roboto Mono" }}
                    padding="checkbox"
                  >
                    {order.isRefunded ? (
                      <Button disabled={true} variant="contained" size="small">
                        Refunded ({moment(order.dateRefunded).format("MM/DD")})
                      </Button>
                    ) : (
                      <Button
                        color="secondary"
                        variant="contained"
                        size="small"
                        onClick={() => this.refundOrder(order.id)}
                      >
                        Refund
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )
    );
  }

  formatOrders(orders) {
    return new Promise(async (resolve, reject) => {
      const mapped = orders.map((order) => {
        return new Promise((resolve, reject) => {
          const { game, seat } = order;

          let gameName = "-";
          let gameDate = "-";

          if (game) {
            gameName = game.longName;

            gameDate =
                (game.isTbd)
                  ? "TBD"
                  : moment
                      .tz(order.game.date, order.game.timezone)
                      .format("MMMM D, YYYY");
          }

          resolve({
            Notes: order.notes,
            Team: this.team(game.homeTeamSlug),
            Date: moment(order.createdAt).format("MMMM D, YYYY"),
            Name: order.name,
            Email: order.email,
            Game: `${game.opponent} at ${this.teamLongName(game.homeTeamSlug)}`,
            "Game Date": gameDate,
            Seat: seat && `${seat.zone} ${seat.zoneNo} Row ${seat.row}`,
            "No of Seats": order.noOfSeats,
            Total: order.ticketPrice * order.noOfSeats,
            "Seller Email": seat && seat.sellerEmail,
            Sent: order.isSent ? "Sent" : "Send",
            Paid: order.isPaid ? "Paid" : "Pay",
            Refunded: order.isRefunded ? "Refunded" : "Refund",
          });
        });
      });

      const resolved = await Promise.all(mapped);
      const sorted = _.sortBy(resolved, "date", "desc");

      resolve(sorted);
    });
  }

  async generateReport() {
    this.setState({
      isGeneratingReport: true,
    });

    const { orders } = this.state;

    const formattedOrders = await this.formatOrders(orders);

    this.setState({
      csvReport: formattedOrders,
      isGeneratingReport: false,
    });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleStatusChange(e) {
    const orderStatus = e.target.value;

    this.setState({
      orderStatus,
    });
  }

  renderForm() {
    const { startDate, endDate, orderStatus } = this.state;
    const { classes } = this.props;

    return (
      <form onSubmit={this.getOrders}>
        <TextField
          id="startDate"
          label="Start Date"
          type="date"
          name="startDate"
          value={startDate}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={this.state.isLoading}
          onChange={this.handleChange}
        />
        <TextField
          id="endDate"
          label="End Date"
          type="date"
          name="endDate"
          value={endDate}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={this.state.isLoading}
          onChange={this.handleChange}
          style={{ marginLeft: "1.5rem", marginBottom: "1rem" }}
        />
        <Select
          value={orderStatus}
          onChange={this.handleStatusChange}
          style={{ marginBottom: "1rem", marginRight: "1rem" }}
        >
          <MenuItem value={0}>All Orders</MenuItem>
          <MenuItem value={1}>Sent</MenuItem>
          <MenuItem value={2}>Not Sent</MenuItem>
          <MenuItem value={3}>Unpaid</MenuItem>
          <MenuItem value={4}>Paid</MenuItem>
          <MenuItem value={5}>Refunded</MenuItem>
        </Select>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          disabled={this.state.isLoading}
          style={{ marginBottom: "1rem", marginRight: "1rem" }}
        >
          Get Orders
        </Button>
      </form>
    );
  }

  render() {
    const { classes } = this.props;
    const { csvReport, isGeneratingReport } = this.state;

    return (
      <div className={classes.root} id="Orders">
        <Grid container>
          <Grid item xs={12} md={10}>
            <Typography variant="display2" style={{ marginBottom: "2rem" }}>
              Orders
            </Typography>
            {this.renderForm()}
          </Grid>
          <Grid item xs={12} md={2}>
            {!csvReport && (
              <Button
                variant="contained"
                color="primary"
                disabled={isGeneratingReport}
                onClick={() => this.generateReport()}
                className={classes.headerBtn}
              >
                Generate Report
              </Button>
            )}
            {csvReport && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.headerBtn}
              >
                <CSVLink
                  data={csvReport}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Download Report
                </CSVLink>
              </Button>
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            {/* {this.state.isLoading && (
              <Typography
                variant="headline"
                align="center"
                style={{ marginTop: '2rem' }}
              >
                Loading Orders...
              </Typography>
            )} */}
            {this.renderOrders()}
            <div className={classes.loadMoreBtns}>
              <Button
                onClick={this.load50More}
                disabled={this.state.isLoading}
                variant="contained"
                color="secondary"
                className={classes.loadMoreBtn}
              >
                Load 50 More
              </Button>
              <Button
                onClick={this.load500More}
                disabled={this.state.isLoading}
                variant="contained"
                color="secondary"
                className={classes.loadMoreBtn}
              >
                Load 500 More
              </Button>
            </div>
          </Grid>
        </Grid>
        {this.renderNoteForm()}
        {this.renderOrderDetails()}
        {this.renderResendReceipt()}
      </div>
    );
  }
}

export default withStyles(styles)(Orders);
