import _ from "lodash";
import { get } from "lodash";
import moment from "moment";
import TEAMS from "../../constants/Teams";

export const getSeatLabel = (seat) => {
  const labelPrefix =
    Number(seat.seatNumberStart) === Number(seat.seatNumberEnd)
      ? `Seat ${seat.seatNumberStart}`
      : `Seats ${seat.seatNumberStart} - ${Number(seat.seatNumberStart) + Number(seat.noOfSeats) - Number(seat.seatsSold) - 1}`;
  const seatNumberLabel = Number(seat.seatNumberStart) > 0 ? labelPrefix : "-";
  return seatNumberLabel;
};

export const renderSeatNumbers = (
  seat,
  emptyLabel = "",
) => {
  const numbersLabel =
    Number(seat.seatNumberStart) === Number(seat.seatNumberEnd)
      ? `Seat ${seat.seatNumberStart}`
      : `Seats ${seat.seatNumberStart} - ${Number(seat.seatNumberEnd)}`;

  if ((Number(seat.seatNumberStart) > 0)) {
    return numbersLabel;
  } else {
    return emptyLabel;
  }
};

export const getLocalizedDateTime = (date, timezone, formatHour = "H:mm") => {
  const dateWithTimeZone = moment
    .tz(Number(date), timezone)
    .format("YYYY-MM-DD");
  const timeWithTimeZone = moment
    .tz(Number(date), timezone)
    .format(formatHour);
  return {
    stringDate: dateWithTimeZone,
    stringTime: timeWithTimeZone,
  }
}

export const getZoneLabel = (seat) => {
  const otherZones = [
    "Sportsnet",
    "Sky Lounge",
    "Press Level",
    "Press Level East General Admission",
    "Press Level West General Admission",
    "Loge", "REST",
    "TM Lounge",
    "WestJet Flight Deck",
    "TM LOUNGE",
    "WESTJET FLIGHT DECK",
    "Platinum Club",
    "Twisted Tea Party Deck",
    "Coors Light Party Deck",
    "Rooftop Patio",
    "CLUBHOUSE",
    "Jim Beam Social",
    "The Den",
    "Side Seats",
    "Field",
    "The Striply",
    "Touchdown Lounge",
    "Stelco Northend",
    "PREMIUM SUITE LOUNGE",
    "CORONA ROOFTOP PATIO",
    "HOME RUN ZONE",
    "LEFT FIELD BALCONY",
    "RIGHT FIELD BLEACHERS",
    "ROGERS LANDING",
    "SCHNEIDERS PORCH",
    "TD PARK SOCIAL",
    "THE CATCH BAR",
    "THE STOP",
    "General Admission",
    "ENDZONE",
    "Floor North",
    "Floor South",
    "Floor East",
    "Floor West",
  ];
  if (otherZones.some((x) => seat.zone.includes(x))) {
    return `${seat.zone} ${seat.zoneNo ? seat.zoneNo : ""}`
  }

  const letter = seat.zone.split(" ").pop();
  const homeTeamSlug = get(seat.gameDetails, "homeTeamSlug");
  const isSpecial = get(seat.gameDetails, "isSpecial", false);

  if (homeTeamSlug === "toronto-blue-jays") {
    // format zone name
    const letter = seat.zone.split(" ").pop();
    if (["WL", "WR"].includes(letter)) {
      const parts = letter.match(/.{1}/g);
      return `${parts[0]}${seat.zoneNo}${parts[1]}`;
    } else if (["W"].includes(letter)) {
      return `${letter}${seat.zoneNo}`;
    } else if (
      ["L", "R", "AR", "AL", "BR", "BL", "CR", "CL", "DR", "DL", "A", "B", "C", "D"].includes(
        letter
      )
    ) {
      return `${seat.zoneNo}${letter}`;
    }
  } else if ((homeTeamSlug === "edmonton-oilers" && isSpecial) || homeTeamSlug === "edmonton-elks") {
    const letters = ["S", "AA", "BB", "CC", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "DD", "EE", "FF", "GG", "HH", "JJ", "KK", "LL", "MM", "NN", "OO", "N", "O", "P", "Q", "R", "T", "U", "V", "W", "X", "Y", "Z", "PP", "QQ", "RR"];
    if (letters.includes(letter)) {
      return `${letter}${seat.zoneNo ? seat.zoneNo : ''}`;
    }
  } else if (homeTeamSlug === "toronto-argonauts") {
    if (["A"].includes(letter)) {
      return `${seat.zone.replace(letter, "")}${seat.zoneNo}${letter}`;
    }
  } else if (homeTeamSlug === "ottawa-redblacks") {
    if ((seat.zone.indexOf('Upper Zone') > -1) || (seat.zone.indexOf('Lower Zone') > -1)) {
      var removeZone = function (txt) {
        var zonesArray =
          [
            "Upper Zone", "Lower Zone"
          ];

        var expStr = zonesArray.join("|");
        return txt.replace(new RegExp('\\b(' + expStr + ')\\b', 'gi'), ' ')
          .replace(/\s{2,}/g, ' ');
      }
      return removeZone(seat.zone)
    } else {
      return `${seat.zoneNo}`
    }
  } else if (homeTeamSlug === "calgary-stampeders") {
    const zoneSplit = String(seat.zone).split(' ');
    return zoneSplit.length === 3 ? zoneSplit[2] : zoneSplit[0];
  } else if (homeTeamSlug === "saskatchewan-roughriders") {
    const letters = ["A"];
    if (letters.includes(letter)) {
      return `${seat.zoneNo}${letter}`;
    } else if (seat.zone.replace(/[^a-zA-Z]+/g, '').toUpperCase() === 'PILCOUNTRYSTANDINGROOMONLY') {
      return `Pil Country Standing Room Only +19 years of age`;
    }
  } else if (homeTeamSlug === "hamilton-tigercats") {
    if (seat.zone.includes("Premium Zone")) {
      return `${letter}${seat.zoneNo ? seat.zoneNo : ''}`;
    }
  } else if (homeTeamSlug === "abbotsford-canucks") {
    const letter = seat.zone.split(" ").pop();
    if (["A", "B"].includes(letter)) {
      return `${seat.zoneNo}${letter}`;
    }
  }

  return `${seat.zoneNo}`
}

export const getNonAlcoholLabel = (seat) => {
  switch (seat.gameDetails.homeTeamSlug) {
    case "calgary-stampeders": {
      const isNonAlcoholZone = seat.zone === 'Lower Zone L';
      return isNonAlcoholZone ? " (Family Zone - No Alcohol)" : ''
    }

    default:
      return "";
  }
}

export const renderZoneName = (zone, zoneNo, row, homeTeamSlug, isSpecial = false) => {
  const zoneNumber = zoneNo ? zoneNo : "";
  if (homeTeamSlug === "toronto-blue-jays") {
    // format zone name
    const letter = zone.split(" ").pop();
    if (["WL", "WR"].includes(letter)) {
      const parts = letter.match(/.{1}/g);
      return `${zone.replace(letter, "")}${parts[0]}${zoneNo}${parts[1]}`;
    } else if (["W"].includes(letter)) {
      return `${zone.replace(letter, "")}${letter}${zoneNo}`;
    } else if (
      ["R", "AR", "AL", "BR", "BL", "CR", "CL", "DR", "DL", "A", "B", "C", "D"].includes(
        letter
      )
    ) {
      return `${zone.replace(letter, "")}${zoneNo}${letter}`;
    } else if (["L"].includes(letter)) {
      if (zone == 'Lower Zone L') {
        return `Lower Zone ${zoneNo}${letter}`;
      } else {
        return `${zone.replace(letter, "")}${zoneNo}${letter}`;
      }
    } else if (zone.replace(/[^a-zA-Z]+/g, '').toUpperCase() === 'WESTJETFLIGHTDECK') {
      return `WESTJET FLIGHT DECK Standing Room Only`;
    }
  } else if (homeTeamSlug === "edmonton-oilers") {
    const zoneOilers = zone.replace(/Upper Zone/g, 'Upper Bowl').replace(/Lower Zone/g, 'Lower Bowl');
    let result = `${zoneOilers} ${zoneNo ? zoneNo : ""}`;

    if (isSpecial) {
      const letter = zone.split(" ").pop();
      const letters = ["S", "AA", "BB", "CC", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "DD", "EE", "FF", "GG", "HH", "JJ", "KK", "LL", "MM", "NN", "OO", "N", "O", "P", "Q", "R", "T", "U", "V", "W", "X", "Y", "Z", "PP", "QQ", "RR"];

      if (letters.includes(letter)) {
        if (zone.includes("Upper Bowl")) {
          result = `Upper Bowl ${letter}${zoneNumber}`;
        } else if (zone.includes("Lower Bowl")) {
          result = `Lower Bowl ${letter}${zoneNumber}`;
        }
      }
    }

    if ((zoneNo >= 101 && zoneNo <= 104) || (zoneNo >= 118 && zoneNo <= 121)) {
      result = result.replace(/Lower Bowl/g, 'Lower Club');
    } else if ([107, 109, 113, 115].includes(zoneNo) && ["23", "24", "25"].includes(row)) {
      result = result.replace(/Lower Bowl/g, 'Ozone Club');
    }

    return result;
  } else if (homeTeamSlug === "ottawa-redblacks") {
    return `${zone} ${Number(zoneNo) > 0 ? zoneNo : ""}`;
  } else if (homeTeamSlug === "bc-lions") {
    const zoneLions = String(zone).toLowerCase().includes("middle zone") ? String(zone).replace("Middle Zone", "Club Seats") : zone;
    return `${zoneLions} ${zoneNo ? zoneNo : ""}${getNonAlcoholLabel({ zone: zoneLions, zoneNo, gameDetails: { homeTeamSlug } })}`;
  } else if (homeTeamSlug === "toronto-argonauts") {
    const letter = zone.split(" ").pop();
    if (
      ["A"].includes(
        letter
      )
    ) {
      return `${zone.replace(letter, "")}${zoneNo}${letter}`;
    }
  } else if (homeTeamSlug === "edmonton-elks") {
    const letter = zone.split(" ").pop();
    if (
      ["S", "AA", "BB", "CC", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "DD", "EE", "FF", "GG", "HH", "JJ", "KK", "LL", "MM", "NN", "OO", "N", "O", "P", "Q", "R", "T", "U", "V", "W", "X", "Y", "Z", "PP", "QQ", "RR"].includes(
        letter
      )
    ) {
      if (zoneNo) {
        return `${zone}${zoneNo}`;
      } else {
        return `${zone} ${zoneNo ? zoneNo : ""}`;
      }
    }
  } else if (homeTeamSlug === "saskatchewan-roughriders") {
    const letter = zone.split(" ").pop();
    if (
      ["A"].includes(
        letter
      )
    ) {
      return `${zone.replace(letter, "")}${zoneNo}${letter}`;
    } else if (zone.replace(/[^a-zA-Z]+/g, '').toUpperCase() === 'PILCOUNTRYSTANDINGROOMONLY') {
      return `Pil Country Standing Room Only +19 years of age`;
    }
  } else if (homeTeamSlug === "hamilton-tigercats") {
    if (zone.includes("Premium Zone")) {
      return `${zone}${zoneNumber}`;
    }
  } else if (homeTeamSlug === "winnipeg-blue-bombers" || homeTeamSlug === "grey-cup") {
    if ((zoneNo >= 106 && zoneNo <= 109) && (row >= 9)) {
      return `${zone.replace(/Lower Zone/g, 'Pinnacle Club')} ${zoneNo ? zoneNo : ""}${getNonAlcoholLabel({ zone, zoneNo, gameDetails: { homeTeamSlug } })}`;
    }
  } else if (homeTeamSlug === "abbotsford-canucks") {
    const letter = zone.split(" ").pop();
    if (["A", "B"].includes(letter)) {
      return `${zone.replace(letter, "")}${zoneNo}${letter}`;
    }
  } else if (homeTeamSlug === "vancouver-canucks"){
    if (isSpecial){
      const letter = zone.split(" ").pop();
      if (["A", "B"].includes(letter)) {
        return `${zone.replace(letter, "")}${zoneNo}${letter}`;
      }
    }
  } else if (homeTeamSlug === "belleville-senators"){
    const letter = zone.split(" ").pop();
    if (["L"].includes(letter)) {
      if (zone == 'Lower Zone L') {
        return `Lower Zone ${zoneNo}${letter}`;
      } else {
        return `${zone.replace(letter, "")}${zoneNo}${letter}`;
      }
    } else if (["Box"].includes(letter)) {
      if (zone == 'Lower Zone Box') {
        return `Lower Zone ${zoneNo} ${letter}`;
      } else {
        return `${zone.replace(letter, "")}${zoneNo} ${letter}`;
      }
    }
  }


  return `${zone} ${zoneNo ? zoneNo : ""}${getNonAlcoholLabel({ zone, zoneNo, gameDetails: { homeTeamSlug } })}`;
};

export const renderRowName = (zone, rowNo, homeTeamSlug, isSpecial = false) => {
  const zeroPad = (num, places) => String(num).padStart(places, '0')

  if (homeTeamSlug === "toronto-blue-jays") {
    if (zone.replace(/[^a-zA-Z]+/g, '').toUpperCase() === 'WESTJETFLIGHTDECK') {
      return `GA${zeroPad(rowNo.replace(/\D/g, ''), 2)}`;
    }
  }
  if (homeTeamSlug === "bc-lions" && isSpecial) {
    return rowNo.replace(/\d*-GA|GA\d*|-GA|GA-/g, 'GA');
  }
  return rowNo;
};

export const renderBlueJaysLabel = (label) => {
  const zoneNo = label.split(" ").pop();
  const zone = label.replace(zoneNo, "").trim();
  const letter = zone.split(" ").pop();
  if (["W"].includes(letter)) {
    return `${zone.replace(letter, "")}${letter}${zoneNo}`;
  } else if (["R", "AR", "AL", "BR", "BL", "CR", "CL", "DR", "DL", "A", "B", "C", "D"].includes(letter)) {
    return `${zone.replace(letter, "")}${zoneNo}${letter}`;
  } else if (["L"].includes(letter)) {
    if (zone == 'Lower Zone L') {
      return `Lower Zone ${zoneNo}${letter}`;
    } else {
      return `${zone.replace(letter, "")}${zoneNo}${letter}`;
    }
  } else {
    return label;
  }
}

export const testDataCheck = (game) => {
  const url = window.location.href;
  let isDevelopmentServer = false;
  if (url.includes('development') || url.includes('localhost')) {
    isDevelopmentServer = true
  }
  return (isDevelopmentServer && game.testGame) || !game.testGame ? false : true;
}

export const getTeamAbbreviation = (homeTeamSlug) => {
  const team = TEAMS.find(team => team.homeTeamSlug === homeTeamSlug);
  if (team) {
    return team.abbreviation;
  }
  return null;
}

export const getTeamName = (homeTeamSlug) => {
  const team = TEAMS.find(team => team.homeTeamSlug === homeTeamSlug);
  if (team) {
    return team.name;
  }
  return null;
}

export const getTeamFullName = (homeTeamSlug) => {
  const team = TEAMS.find(team => team.homeTeamSlug === homeTeamSlug);
  if (team) {
    return team.fullName;
  }
  return null;
}
