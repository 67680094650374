import React, { Component } from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OILERS_ROWS from "../constants/OilersSeatRows";

class UfcEdmontonRowSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0
    };
  }

  generateNumberRows(start, end) {
    const arr = [];
    for (let i = start; i <= end; i++) {
      arr.push({
        value: i,
        label: `Row ${i}`,
      });
    }
  
    return arr;
  }

  getRows() {
    const { section } = this.props;
    if (section.includes("Floor North")) {
      return this.generateNumberRows(1, 4);
    } else if (section.includes("Floor South")) {
      return this.generateNumberRows(1, 3);
    } else if (section.includes("Floor East")) {
      return this.generateNumberRows(1, 17);
    } else if (section.includes("Floor West")) {
      return this.generateNumberRows(1, 19);
    } else if (section === "Sky Lounge AA") {
      return OILERS_ROWS.SKY_LOUNGE_AA_ROWS;
    } else if (section === "Sky Lounge A") {
      return OILERS_ROWS.SKY_LOUNGE_A_ROWS;
    } else if (section === "Sky Lounge B") {
      return OILERS_ROWS.SKY_LOUNGE_B_ROWS;
    } else if (section === "Sky Lounge C") {
      return OILERS_ROWS.SKY_LOUNGE_C_ROWS;
    } else if (section === "Sky Lounge D") {
      return OILERS_ROWS.SKY_LOUNGE_D_ROWS;
    } else if (section === "Sky Lounge E") {
      return OILERS_ROWS.SKY_LOUNGE_E_ROWS;
    } else if (section === "Sportsnet Club A") {
      return OILERS_ROWS.SPORTSNET_A_ROWS;
    } else if (section === "Sportsnet Club B") {
      return OILERS_ROWS.SPORTSNET_B_ROWS;
    } else if (section === "Sportsnet Club C") {
      return OILERS_ROWS.SPORTSNET_C_ROWS;
    } else if (section === "Sportsnet Club D") {
      return OILERS_ROWS.SPORTSNET_D_ROWS;
    } else if (section === "Sportsnet Club E") {
      return OILERS_ROWS.SPORTSNET_E_ROWS;
    } else if (section && section.includes("Sky Lounge")) {
      return OILERS_ROWS.SKY_LOUNGE_ROWS;
    } else if (section && section.includes("Sportsnet Club")) {
      return OILERS_ROWS.SPORTSNET_ROWS;
    } else if (section && section.includes("Loge")) {
      return OILERS_ROWS.LOGE_ROWS;
    } else if (["Lower Bowl 107", "Lower Bowl 109", "Lower Bowl 113", "Lower Bowl 115"].includes(section)) {
      return OILERS_ROWS.OZONE_CLUB_ROWS;
    } else {
      return OILERS_ROWS.DEFAULT_ROWS;
    }
  };

  componentWillMount() {
    this.setState({
      value: this.props.row,
      section: this.props.section
    });
  }

  handleSelection = (event) => {
    this.setState({
      value: event.target.value,
    });

    this.props.onRowSelection(event.target.value);
  };  

  render() {
    return (
      <FormControl>
        <InputLabel>Row</InputLabel>
        <Select
          disableUnderline={true}
          value={this.state.value}
          onChange={this.handleSelection}
          inputProps={{
            name: "row",
            id: "row",
          }}
          placeholder="Seat Row"
          fullWidth
        >
          {this.props.section && this.getRows().map((row, index) => (
            <MenuItem selected={row.value === this.state.value} value={row.value} key={index}>
              {row.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

UfcEdmontonRowSelect.propsType = {
  classes: PropTypes.object.isRequired,
  onRowSelection: PropTypes.func.isRequired,
  row: PropTypes.string.isRequired,
};

export default UfcEdmontonRowSelect;
